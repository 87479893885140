<template>
  <div>
    <div class="boxs">
      <el-empty v-if="isEmpty" description="暂无内容"></el-empty>
      <div class="tableBox">
        <el-tabs v-model="question_bank_id" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in questionbank_data_title"
            :key="item.id"
            :label="item.question_bank_name"
            :name="item.question_bank_id + ''"
          ></el-tab-pane>
        </el-tabs>
        <div class="dBox" v-for="(it, index) in treeData" :key="it.id">
          <div class="tis">
            <span>{{ ++index }}</span>
            <span>{{ it.title }}</span>
          </div>
          <div class="slis">{{ it.title }}</div>
          <ul>
            <li
              v-for="i in it.questionDetailData"
              :key="i.id"
              @click="handleNodeClick(i)"
            >
              <span class="t1 tt1">{{ i.title }}</span>
              <div>
                <span :class="i.type == 1 ? 't1' : i.type == 2 ? 't2' : 't3'">{{
                  i.type == 1 ? "简单" : i.type == 2 ? "复杂" : "困难"
                }}</span>
                <span>{{ i.total_count }}人</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- <el-tree
          :data="treeData"
          :props="defaultProps"
          @node-click="handleNodeClick"
          v-loading="treeLoading"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <div class="flexBox">
              {{ data.title }}
              <div>
                <span
                  v-if="data.type"
                  :class="data.type == 1 ? 't1' : data.type == 2 ? 't2' : 't3'"
                  >{{
                    data.type == 1 ? "简单" : data.type == 2 ? "复杂" : "困难"
                  }}</span
                >
                <span
                  v-if="typeof data.total_count === 'number'"
                  style="margin: 0 20px 0 60px"
                  >完成人数{{ data.total_count }}</span
                >
              </div>
            </div>
          </span>
        </el-tree> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as http from "@/api/order.js";

export default {
  data() {
    return {
      grade_id: sessionStorage.getItem("gradeID"),
      paperInfo: {},
      treeData: [],
      defaultProps: {
        children: "questionDetailData",
        label: "title",
      },
      userInfo: {},
      question_bank_id: "",
      questionbank_data_title: [],
      treeLoading: false,
      isEmpty: true,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getList();
  },
  components: {},
  methods: {
    handleClick(tab) {
      console.log(tab.name);
      this.question_bank_id = tab.name;
      this.getList();
    },
    toDoQuestionFn() {},
    getList() {
      this.treeLoading = true;
      let params = {
        grade_id: this.grade_id,
        student_id: this.userInfo.id,
        question_bank_id: this.question_bank_id,
      };
      if (this.question_bank_id == 0) {
        params.question_bank_id = "";
      }
      http.questionbank_index({ ...params }).then((res) => {
        this.questionbank_data_title = res.questionbank_data_title;
        this.treeData = res.questionbankCategory_data;
        if (this.treeData.length) {
          this.isEmpty = false;
        } else {
          this.isEmpty = true;
        }
        this.treeLoading = false;
        if (!params.question_bank_id) {
          this.question_bank_id =
            res.questionbank_data_title[0].question_bank_id + "";
        }
      });
    },
    handleNodeClick(data) {
      if (!data.questionDetailData) {
        let index = this.questionbank_data_title.findIndex((i) => {
          return i.question_bank_id == this.question_bank_id;
        });
        const tikuName = this.questionbank_data_title[index].question_bank_name;
        const news = this.$router.resolve({
          name: "doQuestion",
          query: {
            question_id: data.id,
            question_bank_id: this.question_bank_id,
            student_id: this.userInfo.id,
            grade_id: this.grade_id,
            questionbankCategory_data: JSON.stringify(this.treeData),
            tikuName: tikuName,
          },
        });
        window.open(news.href, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tableBox {
  padding-bottom: 70px;
  box-sizing: border-box;
}
::v-deep .el-tree-node {
  margin-bottom: 10px;
  background: #eeeeee;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
}
::v-deep .custom-tree-node {
  width: 100%;
}
.flexBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .el-tree-node.is-expanded > .el-tree-node__children .el-tree-node {
  padding: 4px 10px;
  box-sizing: border-box;
  margin: 0;
  .el-tree-node__content {
    padding: 4px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
.t1 {
  color: #3b97ff;
}
.tt1 {
  color: #333333;
}
.t2 {
  color: #32ca99;
}
.t3 {
  color: #ffaa20;
}
.dBox {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f8f8f8;
  margin-bottom: 10px;
  .tis {
    font-size: 18px;
    font-weight: 700;
    span {
      &:first-child {
        background: linear-gradient(135deg, #ffc680 0%, #fd7023 100%);
        border-radius: 10px;
        color: #fff;
        content: counter(section, decimal-leading-zero);
        counter-increment: section;
        font-variant: tabular-nums;
        margin-right: 10px;
        padding: 0 12px;
      }
    }
  }
  .slis {
    color: #555555;
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 14px 14px 0 0;
    background-color: #f8f8f8;
    font-weight: 700;
    margin-top: 10px;
    font-size: 16px;
  }
  ul {
    margin-top: 10px;
    li {
      display: flex;
      font-size: 14px;
      line-height: 42px;
      padding-left: 30px;
      box-sizing: border-box;
      border: 1px solid #fff;
      position: relative;
      &:hover {
        background-color: #f8f9fb;
        border: 1px solid #f3f3f6;
        cursor: pointer;
      }
      .t1 {
        display: inline-block;
        width: 60%;
        &::before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #ddd;
          position: absolute;
          left: 10px;
          top: 40%;
        }
      }
      div {
        padding: 0 6%;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
::v-deep .el-tree-node__content {
  height: auto;
  margin-left: 0;
}
::v-deep .el-tree-node__content .el-tree-node {
  background: #fff;
}
::v-deep .el-tree-node__content:hover,
.el-upload-list__item:hover,
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background: #eeeeee;
}
::v-deep .el-icon-caret-right,
::v-deep .el-tree-node__expand-icon.expanded {
  pointer-events: none;
}
::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  padding: 0;
}
::v-deep .el-tree-node__expand-icon {
  font-size: 20px;
  margin-right: 4px;
}
</style>
